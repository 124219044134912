import { useEffect, useMemo, useRef, useState } from "react";
import { TableUserContainer, RowContent } from "./style";
import { TableUserProps } from "./types";
import OptionsIcon from "@/assets/icons/options";
import EditIcon from "@/assets/icons/edit";
import TrashIcon from "@/assets/icons/trash";
import { UserType } from "@/types/user.types";
import { handleGetMembers } from "@/services";
import { useInfiniteQuery } from "@tanstack/react-query";

export function TableUser(props: TableUserProps) {
  const {
    title = 'Usuários',
  } = props;
  const [rowSelected, setRowSelected] = useState('');
  const { data, fetchNextPage, fetchPreviousPage } = useInfiniteQuery(['members'],
    async ({ pageParam = 1 }) => {
      const res = await handleGetMembers({ page: pageParam, perPage: 20 });
      return res?.data;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        const count = Number((pages.length-1) || 0) + 1?? 1;
        return count;
      },
      getPreviousPageParam: (lastPage, pages) => {
        const count = Number(pages.length || 0) - 1?? undefined;
        return count;
      },
    },
  )


  const onClick = (id: string) => {
    setRowSelected(id);
    if (id === rowSelected) {
      setRowSelected('');
    }
  }

  useEffect(() => {
    setTimeout(() => { if (rowSelected) setRowSelected('') }, 20000);
  }, [rowSelected])

  const type: Record<UserType, string> = {
    admin: 'Administrador',
    manager: 'Gestor'
  }

  // const [showMore, setShowMore] = useState(false);
  const tableRef = useRef(null);
  // const callback = async (entries: any) => {
  //   const [entry] = entries;
  //   if (entry.isIntersecting) {
  //    await fetchNextPage();
  //   }
  // }

  // useEffect(() => {
  //   const observer = new IntersectionObserver(callback, {
  //     root: null,
  //     rootMargin: '0px',
  //     threshold: 0.5,
  //   });
  //   if (tableRef.current) {
  //     observer.observe(tableRef.current);
  //   }

  //   return () => {
  //     if (tableRef.current) observer.unobserve(tableRef.current);
  //   }
  // }, [tableRef, showMore]);
  return (
    <TableUserContainer ref={tableRef} id="user-table">
      <p>{title}</p>
      <div className="card-body">
        <div className="row">
          <div className="col-info">
            <p className="header">Nome</p>
          </div>
          <div className="col-permition">
            <p className="header">Permissão</p>
          </div>
          <div className="col-domain">
            <p className="header">Domínio</p>
          </div>
          <div className="col-actions"></div>
        </div>
        {
          data?.pages[0]?.data?.data?.map((item) => (
            <div className="row" key={item?.email}>
              <div className="col-info body">
                <div className="cell-img">{(item?.fullname || '').charAt(0)}</div>
                <div>
                  <p className="cell-name">{item?.fullname}</p>
                  <p className="cell-email">{item?.email}</p>
                </div>
              </div>
              <div className="col-permition body">
                {type[item?.type as UserType]}
              </div>
              <div className="col-domain body">
                Ver detalhes
              </div>
              <div className="col-actions body">
                <RowContent show={rowSelected === item?.email}>
                  <button
                    className="action-control"
                    onClick={() => onClick(item?.email || '')}>
                    <OptionsIcon />
                  </button>
                  <div className="row-actions">
                    <button><EditIcon /></button>
                    <button><TrashIcon /></button>
                  </div>
                </RowContent>
              </div>
            </div>
          ))
        }

      </div>
      {/* <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <button style={{ border: 'none', backgroundColor: '#f2f2f2', padding: 20, cursor: 'pointer' }} onClick={() => fetchPreviousPage()}>PREV</button>
        <button style={{ border: 'none', backgroundColor: '#f2f2f2', padding: 20, cursor: 'pointer' }} onClick={() => fetchNextPage()}>Next</button>
      </div> */}
    </TableUserContainer>

  )
}
