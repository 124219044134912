import api from './api';
import {
    UserRegister,
    UserLogin,
    UserProfile,
    MembersUpdate,
    Members,
    Permission,
    PasswordUpdate,
} from '@/types/user.types';

export async function handleRegister(params: UserRegister.Request) {
    return await api.post<UserRegister.Response>('Users/Register', { ...params });
};

export async function handleLogin(params: UserLogin.Request) {
    return await api.post<UserLogin.Response>('Users/Login', { ...params });
}

export async function handleGetProfile() {
    return await api.get<UserProfile.Response>(`Users/GetProfile`, {});
}

export async function handleUpdateProfile(params: MembersUpdate.Request) {
    return await api.put<MembersUpdate.Response>(`Users/UpdateProfile`, { ...params });
}

export async function handleGetMembers(query?: Members.Request){
    const queryParams = query ? `?page=${query?.page}&perPage=${query?.perPage}`: '';
    return await api.get<Members.Response>(`Users/ListMembers${queryParams}`, {});
}

export async function handleUpdatePermission(params:Permission.Request) {
    return await api.put<Permission.Response>(`Users/UpdatePermission/${params.uid}`, {...params});
}

export async function handleUpdatePassword(params:PasswordUpdate.Request) {
    return await api.put<PasswordUpdate.Response>(`Users/UpdatePassword`, {...params});
}

