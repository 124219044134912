import { useEffect, useState } from "react";
import { Card } from "./Card";
import { TableUser } from "./TableUser";
import { Modal, SettingsContainer } from "./style";
import { handleGetMembers, handleGetQuotations } from "@/services";
import { MembersData } from "@/types/user.types";
import { QuotationData } from "@/types/quotation.types";
import { handleCreateTax, handleGetTaxs } from "@/services/taxs.service";
import { TaxsData } from "@/types/tax.types";
import { useQuery } from "@tanstack/react-query";

export function Settings() {
  const [users, setUsers] = useState<MembersData[] | undefined>();
  const [quotations, setQuotations] = useState<QuotationData[] | undefined>();
  const [taxs, setTaxs] = useState<TaxsData[] | undefined>();
  const [openNewQuote, setOpenNewQuote] = useState(false);
  const [newQuote, setNewQuote] = useState({percentage:'', type:'rev_share'});
  // const { data:taxs, refetch:refetchTaxs } = useQuery(['taxs'], handleGetTaxs)
  useEffect(() => {
    (async function load() {
      const members = await handleGetMembers({ page: 1, perPage: 10 });
      setUsers(members?.data?.data?.data);
      const quotes = await handleGetQuotations();
      setQuotations(quotes?.data?.data?.data);
       const taxsData = await handleGetTaxs();
      setTaxs(taxsData?.data as TaxsData[]);
      // const taxsData = await handleGetTaxs();
      // setTaxs(taxsData?.data as TaxsData[]);
    })()
  }, []);

const addNewQuote = async () => {
    const res = await handleCreateTax({...newQuote});
    setOpenNewQuote(false)
    const taxsData = await handleGetTaxs();
    console.log('taxsData', taxsData)
    setTaxs(taxsData?.data as TaxsData[]);
    window.location.reload();
    // console.log('handleCreateTax', res);
}
  return (
    <SettingsContainer>
      <div className="settings">
        {quotations && <Card quotations={quotations} />}
        <div className="space"></div>
        {taxs && <Card
          title="Porcentagem de imposto"
          titleButtonLeft="Nova porcentagem"
          onClickButtonLeft={() => setOpenNewQuote(true)}
          taxs={taxs}
        />}
      </div>
      {users && <TableUser data={users} />}

      <Modal show={openNewQuote}>
        <div className="modal-content">
          <div className="modal-header">
            <span className="close" onClick={() => setOpenNewQuote(false)}>&times;</span>
            <h2>Adicionar nova porcentagem</h2>
          </div>
          <div className="modal-body">
             <div className="form-item">
              <label htmlFor="type">Selecione o Tipo:</label>
              <select name="type" id="type" onChange={(e)=> setNewQuote({...newQuote, type:e.target.value})}>
                <option value="rev_share">Compartilhamento de imposto</option>
                <option value="tax">Taxa</option>
              </select>
             </div>
             <div className="form-item">
              <label htmlFor="type">Adicione a porcentagem:</label>
              <input placeholder="0.5" type="number" name="percentage" id="percentage"  onChange={(e)=> setNewQuote({...newQuote, percentage:e.target.value})} />
             </div>
          </div>
          <div className="modal-footer">
            <button disabled={!newQuote.percentage || !newQuote.type} onClick={addNewQuote}>Confirmar</button>
          </div>
        </div>
      </Modal>
    </SettingsContainer>
  );
}
