import colors from '@/styles/colors';
import fonts from '@/styles/fonts';
import styled from 'styled-components';
import { ButtonProps } from './types';

export const ButtonContainer = styled.button<Pick<ButtonProps, 'isSecundary'|'marginBottom'>>`
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: ${p=>p.isSecundary ? colors.transparent: colors.blueDark};
   width: 100%;
   cursor: pointer;
   border: none;
   padding:13px 16px;
   border-radius: 10px;
   margin-bottom: ${p=>p.marginBottom || 0}px;

   p{
      color: ${p=>p.isSecundary? colors.blueDark:colors.white};
      font-size: 16px;
      font-family: ${p=>p.isSecundary? "Inter Bold": fonts.InterMedium};
   }

   :disabled{
      background-color: ${colors.disabled};
     
      p{
         color: ${colors.neutral};
      }
   }
`;

