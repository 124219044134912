import colors from '@/styles/colors';
import fonts from '@/styles/fonts';
import styled from 'styled-components';

export const FilterContainer = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   height: auto;
   padding: 24px 16px;
   box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.1);
   border-radius: 10px;
   background-color: ${colors.white};
   span{
      margin-bottom: 32px;
      font-family: ${fonts.Inter.Regular};
      font-size: 20px;
      line-height: 24px;
   }

   .content{
      display: flex;
      align-items:flex-start;
      justify-content: flex-start;
      flex-direction: column;

      label{
         margin-bottom: 8px;
         font-family: ${fonts.Inter.Regular};
         font-size: 14px;
         line-height: 115%;
      }

      .select{
         display: flex;
         align-items:center;
         justify-content: center;
         flex-direction: column;
         margin-bottom: 32px;

         .options{
            width: 19.188em;
            border: 1px solid ${colors.grayNeutral};
            border-radius: 10px;
            padding: 8px;
            margin-top: 8px;
            gap: 8px;

            .option{
               display: flex;
               align-items:center;
               justify-content: flex-start;
               gap: 10px;
               padding: 8px 9px;
               margin-bottom: 8px;
               :hover{
                  background-color: #F5F8FF;
                  border-radius: 4px;
               }
            }
         }
         #options1{
            height: 235px;
            overflow-y: auto;

            ::-webkit-scrollbar {
               width: 8px;
            }

            ::-webkit-scrollbar-track {
               box-shadow: inset 0 0 5px #F5F8FF; 
               border-radius: 10px;
            }
            
            ::-webkit-scrollbar-thumb {
               background: ${colors.graySoft}; 
               border-radius: 10px;
            }

            ::-webkit-scrollbar-thumb:hover {
               background: ${colors.graySoft}; 
            }
         }
      }
   }
`;


export const SelectedOption = styled.div<{show?: boolean}>`
   display: flex;
   align-items:center;
   justify-content: space-between;
   width: 19.188em;
   min-height: 32px;
   border: 1px solid ${colors.grayNeutral};
   border-radius: 10px;
   padding: 8px;
          
   .selected-chips{
      display: flex;
      align-items:center;
      justify-content: flex-start;  
      flex-direction: row;  
      flex-wrap: wrap;
      max-width: 282px;
      gap:8px;

      span{
         font-family: ${fonts.Inter.Regular};
         font-size: 14px;
         line-height: 16.1px;
         color: ${colors.grayDark};
         margin-bottom: 0px;
         }
      }
      .arrow{
         display: flex;
         align-items:center;
         justify-content: center;
         transform: ${p=>p.show? 'rotate(-180deg)':'rotate(0deg)'};
         transition: transform 500ms;
      }
`;