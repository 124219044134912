import colors from '@/styles/colors';
import styled from 'styled-components';

export const ButtonContainer = styled.button`
   display: flex;
   flex-direction: row;
   align-items: center;
   padding: 4px 12px;
   width: auto;
   height: 32px;
   border: 1px solid ${colors.grayExtraLight};
   border-radius: 10px;
   background-color: ${colors.white};
   cursor: pointer;

   p{
      margin-left: 8px;
   }

   :hover{
      background-color: ${colors.grayExtraLight};
   }
`;
