import colors from '@/styles/colors';
import fonts from '@/styles/fonts';
import styled from 'styled-components';

export const SideBarContainer = styled.aside`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background-color: ${colors.grayExtraLight};
    height: 100%;
    width: auto;
    padding-top: 35px;
    img{
        margin-bottom: 8.75em;
    }
    ul{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    }

    li{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        list-style: none;
        padding: 8px 0px;
        cursor: pointer;
        margin-bottom: 2.5em;
        width: 100%;
        :hover{
            opacity: 0.8;
        }
    }
`;

export const Title = styled.p<{color:string}>`
    font-size: .875em;
    line-height: 17.09px;
    letter-spacing: 1%;
    color: ${p=>p.color || colors.grayDark};
    font-family: ${fonts.Inter.Medium};
    margin-left: 16px;
`;