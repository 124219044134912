import { createGlobalStyle } from "styled-components";
import fonts from "./fonts";

export default createGlobalStyle`
  @font-face {
    font-family: "Inter Thin";
    src: local("Inter-Thin"), url(${fonts.InterThin}) format('truetype');
  }
  @font-face {
    font-family: "Inter SemiBold";
    src: local("Inter-SemiBold"), url(${fonts.InterSemiBold}) format('truetype');
  }
  @font-face {
    font-family: "Inter Regular";
    src: local("Inter-Regular"), url(${fonts.InterRegular}) format('truetype');
  }
  @font-face {
    font-family: "Inter Medium";
    src: local("Inter-Medium"), url(${fonts.InterMedium}) format('truetype');
  }
  @font-face {
    font-family: "Inter Light";
    src: local("Inter-Light"), url(${fonts.InterLight}) format('truetype');
  }
  @font-face {
    font-family: "Inter ExtraLight";
    src: local("Inter-ExtraLight"), url(${fonts.InterExtraLight}) format('truetype');
  }
  @font-face {
    font-family: "Inter ExtraBold";
    src: local("Inter-ExtraBold"), url(${fonts.InterExtraBold}) format('truetype');
  }
  @font-face {
    font-family: "Inter Bold";
    src: local("Inter-Bold"), url(${fonts.InterBold}) format('truetype');
  }
  @font-face {
    font-family: "Inter Black";
    src: local("Inter-Black"), url(${fonts.InterBlack}) format('truetype');
  }

    *{
        margin: 0;
        padding: 0;
        font-family: 'Inter Regular';
    }
    #root{
      height: 100vh;
    }

`