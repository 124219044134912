import colors from "@/styles/colors";

export default function GeneralIcon(props: React.SVGProps<SVGSVGElement>){
    const { color = colors.grayDark } = props;
    return(
        <svg width="24" height="24" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4.5" cy="4.5" r="3.9" stroke={color} strokeWidth="1.2"/>
            <rect y="11" width="9" height="9" rx="1" fill={color}/>
            <rect x="12" width="9" height="9" rx="1" fill={color}/>
            <circle cx="16.5" cy="15.5" r="3.9" stroke={color} strokeWidth="1.2"/>
        </svg>
    )
}