import { ButtonContainer } from "./style"
import { ButtonProps } from "./types"

export function Button(props: ButtonProps){
  const {title,...rest} = props;
  return (
    <ButtonContainer {...rest}>
      <p>{title}</p>
    </ButtonContainer>
  )
}
