import { Filter } from "@/helpers/search";
import { Table } from "./Table";
import { GeneralProps } from "./types";
import { useEffect, useState } from "react";
import { handleGetSites } from "@/services";
import { SiteData } from "@/types/site.types";

export function General({ search, filters, onChangeDomain, onChangeRevenue }: GeneralProps) {
  const [sites, setSites] = useState<SiteData[] | undefined>([]); 
   const [filteredData, setFilteredData] = useState(sites);
   const [page, setPage] = useState({current:1, total:1});
  
  const handleUpdateFilters = async (data:SiteData[]|undefined) => {
    onChangeDomain(data?.map(i => i.domain));
    onChangeRevenue(Object.keys(data?.[0] || '').filter(i => !i.includes('_at') && !i.includes('id') && !i.includes('domain')))
  }

  const handleSites = async () => {
   const data = await handleGetSites({ page: page.current, perPage: 6 });
    setSites(data?.data?.data?.data);
    setFilteredData(data?.data?.data?.data);
    handleUpdateFilters(data?.data?.data?.data);
    setPage({...page, total:data?.data?.data?.pages||1});
  }
  useEffect(() => {
    handleSites();
  }, [page.current]);


  useEffect(() => {
    if (search.length > 1) {
      setFilteredData(Filter(sites, search));
    } else {
      handleSites()
    }
  }, [search]);

  useEffect(() => {
    const tags = filters || [];
    if (tags.length > 0) {
      const customFilter = [...new Set(tags?.map((item) => Filter(sites, item)[0]))];
      if (tags.length > 1) {
        setFilteredData(customFilter);
      } else {
        setFilteredData(sites);
      }
    }

  }, [filters]);
  const prev = () => (page.current > 1 ? setPage({...page, current:page.current - 1}) : setPage({...page, current: 1}));
  const next = () =>
    page.current < (page.total || 0)
      ? setPage({...page, current:page.current + 1})
      : setPage({...page, current: page.total || 1});

  return <Table data={filteredData} prev={prev} next={next} page={page.current} pages={page.total} />
}