import CheckIcon from "@/assets/icons/check";
import { CheckBoxContainer } from "./style"
import { CheckBoxProps } from "./types"

export function CheckBox(props: CheckBoxProps) {
  const { isChecked, onClick } = props;
  return (
    <CheckBoxContainer isChecked={isChecked} onClick={() => onClick && onClick()}>
      <CheckIcon />
    </CheckBoxContainer>
  )
}
