import { FormContainer, TableContainer, TableTr } from "./style";
import { TableProps } from "./types";
import FilterColIcon from "@/assets/icons/filter-col";
import { useEffect, useRef, useState } from "react";
import ArrowDownIcon from "@/assets/icons/arrow-down";
import { Form } from "../Form";
import { TagData } from "@/types/tag.types";
import { handleGetTags } from "@/services";
import { SiteDataDetail } from "@/types/site.types";
import colors from "@/styles/colors";
import dayjs from "dayjs";
import Footer from "@/components/Footer";

export function Table(props: TableProps) {

  const [onOpenForm, setOnOpenForm] = useState(false);
  const [site, setSite] = useState<SiteDataDetail | undefined>(undefined);
  const [tags, setTags] = useState<TagData[] | undefined>();
  const [selectedTag, setSelectedTag] = useState<TagData | undefined>();
  const openForm = (data: SiteDataDetail) => {
    setSite(data);
    setOnOpenForm(!onOpenForm);

    // if(site === data && onOpenForm) {
    //   setOnOpenForm(false);
    // }
    // setOnOpenForm(true);
  }

  useEffect(() => {
    (async function load() {
      const tagsData = await handleGetTags();
      //@ts-ignore
      setTags(tagsData?.data?.data);
    })()
  }, []);

  const data = props.data
  const sumCost = Number(data?.reduce((acc, item) => acc + item?.sum_cost || 0, 0) || 0).toFixed(2);
  const sumRevenue = Number(data?.reduce((acc, item) => acc + item?.sum_revenue || 0, 0) || 0).toFixed(2);
  const refTable = useRef<HTMLTableSectionElement>(null);
  // useEffect(() => {
  //   const handleClickOutside = (event: any) => {
  //     if (refTable.current && !refTable?.current?.contains(event.target)
  //     ) {
  //       setOnOpenForm(false)
  //     }
  //     if (refTable.current && !refTable?.current?.contains(event.target)
  //     ) {
  //       setOnOpenForm(false)
  //     }
  //   };
  //   document.addEventListener('click', handleClickOutside, true);
  //   return () => {
  //     document.removeEventListener('click', handleClickOutside, true);
  //   };
  // }, []);

  return (
    <TableContainer>
      <thead>
        <tr>
          <th scope="col"><span>Data</span>  <FilterColIcon /> </th>
          <th scope="col"><span>URL</span> <FilterColIcon /> </th>
          <th scope="col"><span>País</span>  <FilterColIcon /> </th>
          <th scope="col"><span>C. Impostos</span>  <FilterColIcon /> </th>
          <th scope="col"><span>R. Líquida</span>  <FilterColIcon /> </th>
        </tr>

      </thead>
      <tbody ref={refTable}>
        {
          onOpenForm && site && (
            <FormContainer>
              <Form
                site={site}
                tags={tags}
                selectTag={(v) => setSelectedTag(v)}
              />
            </FormContainer>
          )
        }

        {
          data?.map((item, i) => item && (
            <TableTr key={`${item?.reference}-${i}`} tagColor={site === item ? selectedTag?.hex : colors.white}>
              <td data-label="Date">{String(dayjs(item?.reference).format('DD/MM/YYYY'))}</td>
              <td data-label="URL" onClick={() => openForm(item)}>
                <div>{item?.url} <ArrowDownIcon /></div>
              </td>
              <td data-label="País">{item?.country.charAt(0).toUpperCase() + item?.country.slice(1)}</td>
              <td data-label="C. Impostos">{Number(item?.sum_cost || 0).toFixed(2)}</td>
              <td data-label="R. Líquida">{Number(item?.sum_revenue || 0).toFixed(2)}</td>
            </TableTr>
          ))
        }
      </tbody>

      <tfoot>
        <tr>
          <th scope="col">
            <p>Total</p>
          </th>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col">
            <span>C. Impostos</span>
            <p> R$ {sumCost}</p>
          </th>
          <th scope="col">
            <span>R. Líquida</span>
            <p> R$ {sumRevenue}</p>
          </th>
        </tr>
        <Footer pages={props.pages} page={props.page} onClickNext={props.next} onClickPrev={props.prev} />
      </tfoot>
    </TableContainer>
  )
}
