import { Options } from "@/types/options.types";
import { Avatar } from "../../../components/Avatar";
import { NavHeaderContainer } from "./style"
import { NavHeaderProps } from "./types"
import ProfileIcon from '@/assets/icons/profile.svg';
import LogoutIcon from '@/assets/icons/logout.svg';
import { InputSearch } from "../../../components/InputSearch";
import { ButtonFilter } from "../../../components/ButtonFilter";
import { useNavigate } from "react-router-dom";

export function NavHeader(props: NavHeaderProps) {
  const { title, marginBottom, onChange, onClickFilter, onClickCalendar } = props;
  const navigate = useNavigate();
  const menu: Options[] = [
    {
      title: 'Alterar foto do perfil',
      icon: ProfileIcon,
      action: () => console.log('Alterar foto do perfil')
    },
    {
      title: 'Sair',
      icon: LogoutIcon,
      action: () => {
        localStorage.clear();navigate('/');
      }
    },
  ];
  return (
    <NavHeaderContainer marginBottom={marginBottom} className="nav-header">
      <nav>
        <span>{title}</span>
        <Avatar options={menu} />
      </nav>
      {
        onChange && (
          <div id="control">
            <InputSearch placeholder="Pesquisar domínio" onChange={onChange} />
            <div>
              <ButtonFilter icon="filter" title="Filtro" onClick={onClickFilter} />
              <ButtonFilter icon="calendar" title="Data" onClick={onClickCalendar} />
            </div>
          </div>
        )
      }
    </NavHeaderContainer>
  )
}
