import { Dashboard } from "@/pages/Dashboard";
import { Login } from "@/pages/Login";
import { handleGetProfile } from "@/services/user.service";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import {
    Routes,
    Route,
    BrowserRouter,
    Navigate,
} from "react-router-dom";

function PrivateRoute({ children }: any) {
    const { data } = useQuery(['user'], handleGetProfile)
    const token = useMemo(() => localStorage.getItem('token'), [data]);
    return token ? children : <Navigate to="/" />;
}

function AuthRoute({ children }: any) {
    const token = useMemo(() => localStorage.getItem('token'), []);
    return token ? <Navigate to="/dashboard" /> : children;
}

export default function RoutesComponent() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={
                    <AuthRoute>
                        <Login />
                    </AuthRoute>
                } />
                <Route path="/dashboard" element={
                    <PrivateRoute>
                        <Dashboard />
                    </PrivateRoute>
                } />
            </Routes>
        </BrowserRouter>
    )
}