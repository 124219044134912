import colors from '@/styles/colors';
import fonts from '@/styles/fonts';
import styled from 'styled-components';

export const AvatarContainer = styled.div<{show?: boolean}>`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    z-index: 1;
    cursor: pointer;

    #avatar{
        display: flex;
        justify-content: center;
        align-items: center;
        
        #img{
            width: 50px;
            height: 50px;
            border-radius: 50px;
            margin-right: 16px;
        }

    }
    #drop-down{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        background: ${colors.white};
        border: 1px solid ${colors.graySoft};
        box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        top: 5em;
        position: absolute;
        width:13.188em;

       .drop-down-options{
        width: 100%;
       
        div{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 24px 8px 32px 8px;
                img{
                    margin-right: 16px;
                }
                #avatar-title{
                    font-family: ${fonts.Inter.Medium};
                    font-size: 14px;
                    line-height: 19.94px;
                }
            }
            #hr{
                width: calc(100% -  16px);
                margin: 0px 8px;
                height: 1px;
                padding: 0.1px;
                background-color:${colors.grayExtraLight};
            }
            :hover{
                background-color:#F6F6F6;
            }
       }
    }
    .avatar-icon{
         display: flex;
         align-items:center;
         justify-content: center;
         transform: ${p=>p.show? 'rotate(-180deg)':'rotate(0deg)'};
         transition: transform 500ms;
      }
`;
