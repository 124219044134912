import { useNavigate } from "react-router-dom";
import { LoginContainer, FormContainer } from "./style";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Button } from "@/components/Button";
import Logo from '@/assets/icons/logo.svg';
import { validations } from "@/validations";
import { handleGetProfile, handleLogin } from "@/services/user.service";
import { UserLogin } from "@/types/user.types";
import { useQuery } from "@tanstack/react-query";
import { Input } from "@/components/Input";

export const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(validations),
  });
  const { refetch: refetchUserProfile } = useQuery(['user'], handleGetProfile, { refetchOnWindowFocus: true });
  const navigate = useNavigate();

  const onSubmitHandler = async (data: UserLogin.Request | any) => {
    const response = await handleLogin({ ...data });

    localStorage.setItem('token', response?.data?.data?.access_token || '');
    const res = await refetchUserProfile();
    reset();
    if (response?.data?.data?.access_token) navigate('/dashboard')
  };

  const emailError = !!errors?.email?.message ? String(errors?.email?.message) : '';
  const passwordError = !!errors?.password?.message ? String(errors?.password?.message) : '';
  const disabled = !!emailError || !!passwordError;

  return (
    <LoginContainer>
      <FormContainer onSubmit={handleSubmit(onSubmitHandler)}>
        <img src={Logo} alt="logo" />
        <h1>Login</h1>

        <Input
          label="E-mail"
          marginbottom={40}
          error={emailError}
          type="email"
          {...register('email')}
        />
        <Input
          label="Senha"
          marginbottom={48}
          error={passwordError}
          type="password"
          {...register('password')}
        />

        <Button title="Esqueci minha senha" isSecundary marginBottom={15} />
        <Button disabled={disabled} title="Entrar" type="submit" />
      </FormContainer>
    </LoginContainer>
  )
}
