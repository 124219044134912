import { useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import { NavHeader } from "./NavHeader";
import { SideBar } from "./SideBar";
import { TabType } from "@/types/options.types";
import { General } from "../General";
import { Individual } from "../Individual";
import { Settings } from "../Settings";
import { DashboardContainer } from "./style";
import { Value } from "react-calendar/dist/cjs/shared/types";
import 'react-calendar/dist/Calendar.css';
import { Filter as FilterComponent } from "./Filter";
import '@/styles/calendar.css';

export function Dashboard() {
  const [menu, setMenu] = useState<TabType>('general');
  const [date, setDate] = useState<Value>(new Date());
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState<string[]>();
  const [optionsDomain, setOptionsDomain] = useState<string[]>();
  const [optionsRevenue, setOptionsRevenue] = useState<string[]>();
  const [openCalendar, setOpenCalendar] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  const refCalendar = useRef<HTMLDivElement>(null);
  const refFilter = useRef<HTMLDivElement>(null);
  const commonProps = { search, filters };

  const title: Record<TabType, string> = {
    general: 'Dashboard geral',
    individual: 'Dashboard individual',
    settings: 'Configurações',
  };

  const body: Record<TabType, JSX.Element> = {
    general: <General {...commonProps} onChangeDomain={(v) => setOptionsDomain(v)} onChangeRevenue={(v) => setOptionsRevenue(v)}  />,
    individual: <Individual {...commonProps} date={date} onChangeDomain={(v) => setOptionsDomain(v)} onChangeRevenue={(v) => setOptionsRevenue(v)}  />,
    settings: <Settings />,
  };


  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (refCalendar.current && !refCalendar?.current?.contains(event.target)) {
        setOpenCalendar(false)
      }
      if (refFilter.current && !refFilter?.current?.contains(event.target)) {
        setOpenFilter(false)
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const onClickTab = (tab: TabType) => {
    setMenu(tab);
    setSearch('');
    setOptionsDomain([])
    setOptionsRevenue([])
  }

  const onClickFilters = (opt: string[]) => {
    setFilters(opt);
    setOpenCalendar(false);
    setOpenFilter(false);
  }

  return (
    <DashboardContainer>
      <div id="sidebar">
        <SideBar onClickTab={onClickTab} />
      </div>
      <main>
        <NavHeader
          marginBottom={48}
          title={title[menu]}
          onClickCalendar={() => setOpenCalendar(!openCalendar)}
          onClickFilter={() => setOpenFilter(!openFilter)}
          onChange={menu !== 'settings' ? (v) => setSearch(v.target.value): undefined}
        />
        <div className="filters" ref={refCalendar}>
          {openCalendar && <Calendar selectRange onChange={setDate} value={date} />}
        </div>
        <div className="filters" ref={refFilter}>
          {openFilter && <FilterComponent optionsDomain={optionsDomain || ['']} optionsRevenue={optionsRevenue || ['']} onClick={onClickFilters} />}
        </div>
        {body[menu]}
      </main>
    </DashboardContainer>
  )
}