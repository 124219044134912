import styled from 'styled-components';

export const DashboardContainer = styled.div`
   display: flex;
   justify-content: center;
   align-items: flex-start;
   flex-direction: row;
   height: 100%;
   width: 100%;

   #sidebar{
      width: 15%;
      height: 100%;
      position: fixed;
      left: 0px;
      top: 0px;
   }

   main{
      width: 85%;   
      height: 100%;
      padding: 1em 2.87em;
      margin-left: 15%;
      position: relative;

      .filters{
         position: absolute;
         z-index: 9;
         right:2.87em;
         top: 13em;
      }
   }

    @media screen and (max-width: 1400px) {
      flex-direction: column;
      align-items: center;

      #sidebar{
         width: 100%;
         height: 100%;
         position: relative;
         top: 5em;

         aside{
            padding: 20px 0px;
            height: auto;

            img{
               display: none;
            }
            li{
               margin-bottom: 5px;
            }
         }
      }
      main{
         top: 7em;
         padding: 0em 1.5em;
         margin-left: 0%;

       div.nav-header{
         display: flex;
         justify-content: space-between;
         align-items: center;
         flex-direction: row;
         margin: 10px 0px;
         width: 100%;

         nav{
            
            display: flex;
            justify-content: center;
            align-items: flex-start;
            padding: 0.5em;
            width: 30%;
            #avatar{
               justify-content: flex-start;
            }
            #drop-down{
               min-width: 200px;
               left: 1.5em;
               top: 6em;
            }
            span{
               display: none;
            }
         }
         #control{
            width: 60%;
            margin-top: 0em;
            align-items: flex-end;
            justify-content: flex-end;
            gap:1em;
            input{
               max-width: 10em;
            }
         }
       }

       .filters{
            right:1em;
            top: 7em;
         }
      }
   } 
`;
