import colors from '@/styles/colors';
import styled from 'styled-components';

export const SettingsContainer = styled.div`

   display: flex;
   justify-content: space-between;
   align-items: flex-start;
   gap: 3.438em;
   .settings{
      .space{
         height: 40px;
      }
   }
   @media screen and (max-width: 980px){
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1.250em;
      .settings{
         margin-bottom: 1.5em;
      }
    }
`;

export const Modal = styled.div<{show?:boolean}>`
      display: ${p=>p.show?'block': 'none'}; 
      position: fixed; 
      z-index: 1; 
      left: 0;
      top: 0;
      width: 100%;
      height: 100%; 
      overflow: auto; 
      background-color: rgba(0,0,0,0.4);

   .modal-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 10px;
      background-color: ${colors.white};
      margin: 15% auto; 
      padding: 40px;
      width: 45%;

      .modal-header{
         h2{
            font-size: 1.7em;
            color: ${colors.black};
         }
      }
      .modal-body{
         display: flex;
         flex-direction: column;
         justify-content: center;
         padding-top: 40px;

         .form-item{
            display: flex;
            flex-direction: column;
            margin-bottom: 40px;
            label{
               font-size: 1.2em;
               margin-bottom: 5px;
            }
            select, input{
               border: 1px solid ${colors.gray};
               padding: 15px;
               border-radius: 5px;
            }
            select{
               background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
               background-position: calc(100% - 0.75rem) center !important;
               -moz-appearance:none !important;
               -webkit-appearance: none !important; 
               appearance: none !important;
               padding-right: 2rem !important;
            }
         }
      }
      .modal-footer{
         display: flex;
         justify-content: center;
         width: 100%;
         button{
            width: 100%;
            border: none;
            padding: 15px;
            background-color: ${colors.white};
            border: 1px solid ${colors.primary};
            color: ${colors.primary};
            border-radius: 5px;
            :disabled{
                  border: 1px solid ${colors.gray};
                  color: ${colors.gray};
               }
         }
      }
   }

   .close {
      color: ${colors.gray};
      float: right;
      font-size: 28px;
      font-weight: bold;
   }

   .close:hover,
   .close:focus {
      color: black;
      text-decoration: none;
      cursor: pointer;
   }
`;