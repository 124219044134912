import InterThin from '../assets/fonts/Inter-Thin.ttf';
import InterSemiBold from '../assets/fonts/Inter-SemiBold.ttf';
import InterRegular from '../assets/fonts/Inter-Regular.ttf';
import InterMedium from '../assets/fonts/Inter-Medium.ttf';
import InterLight from '../assets/fonts/Inter-Light.ttf';
import InterExtraLight from '../assets/fonts/Inter-ExtraLight.ttf';
import InterExtraBold from '../assets/fonts/Inter-ExtraBold.ttf';
import InterBold from '../assets/fonts/Inter-Bold.ttf';
import InterBlack from '../assets/fonts/Inter-Black.ttf';


const Inter = {
  Thin : "Inter Thin",
  SemiBold : "Inter SemiBold",
  Regular : "Inter Regular",
  Medium :  "Inter Medium",
  Light : "Inter Light",
  ExtraLight : "Inter ExtraLight",
  ExtraBold : "Inter ExtraBold",
  Bold : "Inter Bold",
  Black : "Inter Black",
}

export default{
  InterThin,
  InterSemiBold,
  InterRegular,
  InterMedium,
  InterLight,
  InterExtraLight,
  InterExtraBold,
  InterBold,
  InterBlack,
  Inter,
}