import colors from '@/styles/colors';
import fonts from '@/styles/fonts';
import styled from 'styled-components';

export const CardContainer = styled.div`
   display: flex;
   justify-content: flex-start;
   align-items: flex-start;
   flex-direction: column;
   max-width: 26.25em;
   width: -webkit-fill-available;
    p{
        margin-bottom:24px;
        font-size: 16px;
        font-family: ${fonts.Inter.Medium};
        color: ${colors.black};
    }
    .card-body{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 1em;
        gap: 1em;
        width: 22em;
        height: auto;
        background-color:${colors.white};
        box-shadow: 0px 2px 10px rgba(137, 137, 137, 0.21);
        border-radius: 10px;
        /* width: -webkit-fill-available; */
        .row{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            span{
                font-size: 16px;
                font-family: ${fonts.Inter.Regular};
                color: #484854;
            }
            .row-value{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .card-footer{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top:24px;
        gap: 24px;
    }

    @media screen and (max-width: 1690px){
        width: 20em;
        .card-body{
            padding: 0.4em;
            width: 19em;
        }
        .card-footer button span{
            margin-left: 5px;
            font-size: 10px;
        }
    }
`;

export const ButtonContainer = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 8px;
    border: 1.5px solid #D23719;
    border-radius: 4px;
    background-color:${colors.transparent};
    cursor: pointer;
    span{
        font-size:0.938em;
        font-family: ${fonts.Inter.Medium};
        color: #484854;
        margin-left: 16px;
        color: ${colors.primary};
    }

`;

export const RowContent = styled.div<{show?: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    button{
        border: none;
        background-color:${colors.transparent};
        cursor: pointer;
    }
    button.action-control{
        transform: ${p=>p.show? 'rotate(90deg)':'rotate(0deg)'};
        transition: transform 500ms;
    }
    .row-actions {
        display: ${p=> p.show ? 'visible': 'none'};
        animation: slide 800ms;

        @keyframes slide {
           from { 
                opacity: 0;
                margin-right: -40px;
            }
            to { 
                opacity: 1;
                margin-right: 0px;
            }
        }
    }
`;