import { Badge } from "@/components/Badge";
import { TableContainer } from "./style";
import { TableProps } from "./types";
import FilterColIcon from "@/assets/icons/filter-col";
import { useMemo } from "react";
import Footer from "@/components/Footer";

export function Table(props: TableProps) {

  const sites = useMemo(() => props?.data, [props?.data]);
  const totalFbCost = sites?.reduce((acc, item) => acc + Number(item?.fbCost || 0), 0).toFixed(2);
  const totalTax = sites?.reduce((acc, item) => acc + Number(item?.tax || 0), 0).toFixed(2);
  const totalAdx = sites?.reduce((acc, item) => acc + Number(item?.adx || 0), 0).toFixed(2);
  const totalGrossRevenue = sites?.reduce((acc, item) => acc + Number(item?.grossRevenue || 0), 0).toFixed(2);
  const totalNetRevenue = sites?.reduce((acc, item) => acc + Number(item?.netRevenue || 0), 0).toFixed(2);
  const totalRoi = sites?.reduce((acc, item) => acc + Number(item?.roi || 0), 0).toFixed(1);

  return (
    <TableContainer>
      <thead>
        <tr>
          <th scope="col"><span>Domínio</span> <FilterColIcon /></th>
          <th scope="col"><span>Custo Facebook</span> <FilterColIcon /></th>
          <th scope="col"><span>Custo Impostos</span> <FilterColIcon /></th>
          <th scope="col"><span>Adx</span> <FilterColIcon /></th>
          <th scope="col"><span>Receita Bruta</span> <FilterColIcon /></th>
          <th scope="col"><span>Receita Líquida</span> <FilterColIcon /></th>
          <th scope="col"><span>% de ROI</span> <FilterColIcon /></th>
        </tr>
      </thead>
      <tbody>
        {
          sites?.map((item) => item && (
            <tr key={item?.domain}>
              <td data-label="Domínio">{item?.domain}</td>
              <td data-label="Custo Facebook">{Number(item?.fbCost || '0').toFixed(2)}</td>
              <td data-label="Custo Impostos">{Number(item?.tax || '0').toFixed(2)}</td>
              <td data-label="Adx">{Number(item?.adx || '0').toFixed(2)}</td>
              <td data-label="Receita Bruta">{Number(item?.grossRevenue || '0').toFixed(2)}</td>
              <td data-label="Receita Líquida">{Number(item?.netRevenue || '0').toFixed(2)}</td>
              <td data-label="ROI"><Badge value={Number(Number(item?.roi || '0').toFixed(1))} /></td>
            </tr>
          ))
        }
      </tbody>
      <tfoot>
        <tr>
          <th scope="col">Total</th>
          <th scope="col">R$ {totalFbCost}</th>
          <th scope="col">R$ {totalTax}</th>
          <th scope="col">R$ {totalAdx}</th>
          <th scope="col">R$ {totalGrossRevenue}</th>
          <th scope="col">R$ {totalNetRevenue}</th>
          <th scope="col"><Badge value={Number(totalRoi || 0)} /></th>
        </tr>
        <Footer pages={props.pages} page={props.page} onClickNext={props.next} onClickPrev={props.prev} />

      </tfoot>

    </TableContainer>
  )
}
