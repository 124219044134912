import React from 'react';
import { FooterProps } from './types';
import { Container } from './style';

const Footer: React.FC<FooterProps> = (props) => {

  return (
    <Container>
          <div className='footer'>
            <div className='indicator'>
                {props?.page} / {props?.pages}
            </div>

            <div className='control'>
              <button onClick={props.onClickPrev}>Antetior</button>
              <button onClick={props.onClickNext}>Próxima</button>
            </div>
          </div>
    </Container>
  )
};

export default Footer;