import { ChipContainer } from "./style"
import { ChipProps } from "./types"
import CloseIcon from "@/assets/icons/close";

export function Chip(props: ChipProps) {
  const { title, onClick } = props;
  return (
    <ChipContainer>
      <span>{title}</span>
      <button onClick={onClick}>
        <CloseIcon />
      </button>
    </ChipContainer>
  )
}
