import { useState } from "react";
import { SideBarContainer, Title } from "./style"
import { SideBarProps } from "./types"
import LogoIcon from '@/assets/icons/logo.svg';
import GeneralIcon from "@/assets/icons/general";
import IndividualIcon from "@/assets/icons/individual";
import SettingsIcon from "@/assets/icons/settings";
import colors from "@/styles/colors";
import { TabType } from "@/types/options.types";


export function SideBar(props: SideBarProps) {
  const { onClickTab,onClickGeneral, onClickIndividual, onClickSettings } = props;

  const [tab, setTab] = useState<TabType>('general');

  const clickGeneral = () => {
    setTab('general');
    if(onClickTab) onClickTab('general')
    if(onClickGeneral) onClickGeneral();
  };
  const clickIndividual = () => {
    setTab('individual');
    if(onClickTab) onClickTab('individual')
    if(onClickIndividual) onClickIndividual();
  };
  const clickSettings = () => {
    setTab('settings');
    if(onClickTab) onClickTab('settings')
    if(onClickSettings) onClickSettings();
  };

  const customColor: Record<TabType, string> = {
    general: tab === "general" ? colors.primary: colors.grayDark,
    individual: tab === "individual" ? colors.primary: colors.grayDark,
    settings: tab === "settings" ? colors.primary: colors.grayDark
  };

  return (
    <SideBarContainer>
      <img src={LogoIcon} alt="logo" />
      <ul>
        <li onClick={clickGeneral}>
          <GeneralIcon color={customColor['general']}  />
          <Title color={customColor['general']}>Dashboard geral</Title>
        </li>
        <li onClick={clickIndividual}>
         <IndividualIcon color={customColor['individual']} />
          <Title color={customColor['individual']}>Dashboard individual</Title>
        </li>
        <li onClick={clickSettings}>
          <SettingsIcon color={customColor['settings']} />
          <Title color={customColor['settings']}>Configurações</Title>
        </li>
      </ul>
    </SideBarContainer>
  )
}
