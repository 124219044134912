import colors from '@/styles/colors';
import fonts from '@/styles/fonts';
import styled from 'styled-components';

export const TableContainer = styled.table`
      border-collapse: collapse;
      margin: 0;
      padding: 0;
      width: 100%;
      table-layout: fixed;
      margin-bottom: 19em;
      
      tr {
         background-color: ${colors.white};
         border: 1px solid ${colors.grayExtraLight};
         padding: .35em;
         border-left-color: ${colors.transparent};
         border-right-color: ${colors.transparent};
         :hover{
            background-color: ${colors.gray100};
         }
      }

      th, td {
         text-align: center;
         padding: 1.5em  0em;
      }
      tr th{
         cursor: pointer;
      }
      th:first-child, td:first-child{
         text-align: left;
      }

      td:last-child{
         display: flex;
         align-items: center;
         justify-content: center;
      }

      th {

         font-size: .875em;
         line-height: 17.09px;
         letter-spacing: 1%;
         padding: 1.5em  .5em;
         color: ${colors.grayDarker};
         font-family: ${fonts.Inter.Medium};

         span{
            margin-right: 0.5em;
         }
      }

      th:last-child{
         display: flex;
         align-items: center;
         justify-content: center;
      }

      td{
         font-size: .875em;
         line-height: 16.94px;
         letter-spacing: 1%;
         color: ${colors.blackLight};
      }
   
      tfoot{
         tr {
            background-color: ${colors.gray100};
         }
      }

      @media screen and (max-width:1200px) {
         display: block;
         max-width: -moz-fit-content;
         max-width: fit-content;
         margin: 0 auto;
         overflow-x: auto;
         white-space: nowrap;
         margin-bottom: 19em;
      }
`;
