import { useState, forwardRef } from "react";
import { InputContainer, InputError } from "./style";
import { InputProps } from "./types";
import DangerIcon from '@/assets/icons/danger.svg';
import EyeIcon from '@/assets/icons/eye.svg';

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ error, label, type, ...rest }, ref): JSX.Element => {

    const [show, setShow] = useState(false);
    const passwordType = show ? "text" : "password";
    const inputType = type == 'password' ? passwordType : type;

    return (
      <InputContainer error={error} marginbottom={48} >
        <p>{label}</p>
        <div>
          <input ref={ref} type={inputType} {...rest} />
          {type === 'password' && <img className="eye-icon" src={EyeIcon} onClick={() => setShow(!show)} />}
        </div>
        {!!error &&
          <InputError>
            <div className="wrapper">
              <img className="danger-icon" src={DangerIcon} />
              <h6>{error}</h6>
            </div>
          </InputError>
        }
      </InputContainer>
    )
  }
)