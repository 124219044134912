import colors from '@/styles/colors';
import fonts from '@/styles/fonts';
import styled from 'styled-components';

export const TableUserContainer = styled.div`
   display: flex;
   justify-content: flex-start;
   align-items: flex-start;
   flex-direction: column;
   /* width: 100%; */

    p{
        margin-bottom:24px;
        font-size: 16px;
        font-family: ${fonts.Inter.Medium};
        color: ${colors.black};
    }
    .card-body{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 1em;
        gap: 1em;
        min-width: 18em;
        /* width: 100%; */
        height: auto;
        background-color:${colors.white};
        box-shadow: 0px 2px 10px rgba(137, 137, 137, 0.21);
        border-radius: 10px;

        .row{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            div .header{
                font-size: 14px;
                font-family: ${fonts.Inter.Medium};
                color: ${colors.blackDark};
            }
            .col-info{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                
                .cell-img{                    
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40px;
                    height: 40px;
                    border-radius: 6.250em;
                    background-color: #4963A7;
                    font-size: 20px;
                    font-family: ${fonts.Inter.SemiBold};
                    color: ${colors.white};
                }
                div{
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    flex-direction: column;
                    margin-left: 16px;
                    .cell-name{
                        font-size: 14px;
                        font-family: ${fonts.Inter.Medium};
                        color: #484854;  
                        margin:0px;                                
                    }
                    .cell-email{
                        font-size: 14px;
                        font-family: ${fonts.Inter.Regular};
                        color: #737185;
                        margin:0px;
                        margin-top: 8px;
                    }
                }

            }
            .col-permition{
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-family: ${fonts.Inter.Regular};
                color: #484854;
                margin:0px;
                width: 12.500em;
            }
            .col-domain{
                font-size: 14px;
                font-family: ${fonts.Inter.Medium};
                color: #D23719;
                width: 12.500em;
            }
            
            .col-actions{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 6.250em;
            }
            .col-info .cell-img {
                margin: 0px;
            }
        }
    }

     @media screen and (max-width: 980px){
        margin-bottom: 2em;
        .card-body{
            padding: 0.5em;
            gap: 0.5em;
        }
        .card-body .row{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border: 1px solid ${colors.grayExtraLight};
            border-radius: 4px;
            padding: 1em 0em;
            position: relative;

            .col-info , .col-permition ,.col-domain {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .col-info.body,.col-permition.body,.col-domain.body{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-left: 1.8em;
            }
            .col-permition.body,.col-domain.body{
                margin: 0.7em;
            }
            .col-actions.body{
                position: absolute;
                top: 10px;
                right: 5px;
                width: 5em;
            }
        }
    }

`;

export const RowContent = styled.div<{show?: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    button{
        border: none;
        background-color:${colors.transparent};
        cursor: pointer;
    }
    button.action-control{
        transform: ${p=>p.show? 'rotate(90deg)':'rotate(0deg)'};
        transition: transform 500ms;
    }
    .row-actions {
        display: ${p=> p.show ? 'flex': 'none'};
        animation: slide 800ms;

        @keyframes slide {
           from { 
                opacity: 0;
                margin-right: -40px;
            }
            to { 
                opacity: 1;
                margin-right: 0px;
            }
        }
    }
    
`;