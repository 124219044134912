import colors from '@/styles/colors';
import styled from 'styled-components';
import fonts from '@/styles/fonts';
import { InputProps } from './types';


export const InputContainer = styled.div<Pick<InputProps,'marginbottom' | 'error'>>`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${p=>p.marginbottom || 0}px;
   
   p{
    color: ${p=> !!p.error ? colors.negative :colors.blackDarker};
    font-size: 14px;
    margin-bottom: 8px;
   }
   
   div{
      width: 100%;
      position: relative;
      
      input{
        width: calc(100% - 34px);
        background-color: ${colors.white};
        border: 1px solid ${p=> !!p.error ? colors.negative: colors.grayLight};
        padding:13px 16px;
        border-radius: 10px;
      }

      img.eye-icon{
         margin: 0px;
         position: absolute;
         top: 25%;
         right: 4%;
         cursor: pointer;
      }
   }
`;



export const InputError = styled.div`
   top: 10px;
   position: relative;

   .wrapper{
      display: flex;
      align-items: center;
      position: absolute;
      
      .danger-icon{
         margin: 0px;
         cursor: default;
      }
      
      h6{
         font-family: ${fonts.Inter.Medium};
         font-size: 0.750em;
         color:${colors.negative};
         margin-left: 8px;
         word-break: break-all;
      }
   }

`;