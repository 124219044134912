export default function HistoryIcon(props: React.SVGProps<SVGSVGElement>) {
    const { color = '#FF6F53' } = props;
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.5 4H5.5C4.94772 4 4.5 4.44772 4.5 5V20C4.5 20.5523 4.94772 21 5.5 21H16.5C17.0523 21 17.5 20.5523 17.5 20V5C17.5 4.44772 17.0523 4 16.5 4Z" fill="#FFFFFF" stroke={color} strokeWidth="1.3" strokeLinejoin="round" />
            <path d="M6.5 7H14.5M6.5 10H12" stroke={color} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
            <rect x="14.5" y="11" width="6" height="6" fill="#FFFFFF" />
            <path d="M17.5 11.0004C18.0933 11.0004 18.6734 11.1763 19.1667 11.506C19.6601 11.8356 20.0446 12.3042 20.2716 12.8524C20.4987 13.4005 20.5581 14.0037 20.4424 14.5857C20.3266 15.1676 20.0409 15.7022 19.6213 16.1217C19.2018 16.5413 18.6672 16.827 18.0853 16.9428C17.5033 17.0585 16.9001 16.9991 16.3519 16.772C15.8038 16.545 15.3352 16.1605 15.0056 15.6671C14.6759 15.1738 14.5 14.5937 14.5 14.0004C14.5 13.8678 14.4473 13.7406 14.3536 13.6468C14.2598 13.5531 14.1326 13.5004 14 13.5004C13.8674 13.5004 13.7402 13.5531 13.6464 13.6468C13.5527 13.7406 13.5 13.8678 13.5 14.0004C13.5001 14.8979 13.802 15.7693 14.3573 16.4745C14.9125 17.1797 15.6887 17.6776 16.5612 17.8883C17.4336 18.0989 18.3516 18.01 19.1674 17.6359C19.9832 17.2618 20.6495 16.6242 21.0591 15.8256C21.4687 15.027 21.5979 14.1138 21.4258 13.233C21.2537 12.3521 20.7903 11.5547 20.1102 10.969C19.4301 10.3834 18.5728 10.0434 17.6762 10.0039C16.7795 9.96435 15.8957 10.2275 15.1667 10.7511V10.5004C15.1667 10.3678 15.114 10.2406 15.0202 10.1469C14.9265 10.0531 14.7993 10.0004 14.6667 10.0004C14.5341 10.0004 14.4069 10.0531 14.3131 10.1469C14.2193 10.2406 14.1667 10.3678 14.1667 10.5004V12.1671C14.1667 12.4431 14.3907 12.6671 14.6667 12.6671H15.6667C15.7993 12.6671 15.9265 12.6144 16.0202 12.5206C16.114 12.4269 16.1667 12.2997 16.1667 12.1671C16.1667 12.0345 16.114 11.9073 16.0202 11.8135C15.9265 11.7197 15.7993 11.6671 15.6667 11.6671H15.614C16.1473 11.2347 16.8134 10.9992 17.5 11.0004ZM17.8333 12.5004C17.8333 12.3678 17.7807 12.2406 17.6869 12.1468C17.5931 12.0531 17.4659 12.0004 17.3333 12.0004C17.2007 12.0004 17.0735 12.0531 16.9798 12.1468C16.886 12.2406 16.8333 12.3678 16.8333 12.5004V14.1671C16.8333 14.4431 17.0573 14.6671 17.3333 14.6671H18.3333C18.4659 14.6671 18.5931 14.6144 18.6869 14.5206C18.7807 14.4269 18.8333 14.2997 18.8333 14.1671C18.8333 14.0345 18.7807 13.9073 18.6869 13.8135C18.5931 13.7197 18.4659 13.6671 18.3333 13.6671H17.8333V12.5004Z" fill={color} />
        </svg>
    )
}