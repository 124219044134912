import * as yup from "yup";

export const validations = yup.object().shape({
    email: yup
          .string()
          .email('O e-mail digitado não é válido')
          .required('O e-mail não pode ser vazio ou nulo'),
    password: yup
        .string()
        .required('A senha não pode ser vazia ou nula.')
      //   .matches(/[A-Z]/g, 'A senha deve conter pelo menos uma letra maiúscula')
      //   .matches(/[a-z]/g, 'A senha deve conter pelo menos uma letra minúscula')
      //   .matches(/\d/g, 'A senha deve conter pelo menos um número')
      //   .matches(/\W/g, 'A senha deve conter pelo menos um caracter especial')
      //   .min(8, 'A senha deve conter pelo menos 8 caracteres')
      //   .max(20, 'A senha deve conter no máximo 20 caracteres')
  });