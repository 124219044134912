import colors from '@/styles/colors';
import fonts from '@/styles/fonts';
import styled from 'styled-components';

export const ChipContainer = styled.div`
   display: flex;
   align-items:center;
   justify-content: center;
   background-color:${colors.grayExtraLight};
   border-radius: 4px;
   width: auto;
   height: auto;

   span{
      font-family: ${fonts.Inter.Light};
      font-size: 14px;
      line-height: 16.7px;
      color: ${colors.grayDark};
      margin-bottom: 0px;
      margin-left: 4px;
   }
   button{
      display: flex;
      align-items:center;
      justify-content: center;
      border: none;
      border-radius: 4px;
      cursor: pointer;
   }
`;

