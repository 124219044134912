import colors from '@/styles/colors';
import styled from 'styled-components';
import { CheckBoxProps } from './types';

export const CheckBoxContainer = styled.button<Pick<CheckBoxProps, 'isChecked'>>`
   display: flex;
   align-items:center;
   justify-content: center;
   border: 1px solid ${p => p.isChecked ? colors.transparent : colors.neutral};
   background-color:${p => p.isChecked ? colors.blueDark : colors.white};
   border-radius: 4px;
   cursor: pointer;
   width: 18px;
   height: 18px;
`;

