import { useMemo, useState } from "react";
import { FormContainer, LiContainer } from "./style";
import { TableProps } from "./types";
import { Input } from "@/components/Input";
import dayjs from "dayjs";
// import { handleCreateComment } from "@/services";

export function Form(props: TableProps) {
  const { site, tags, selectTag } = props;
  const [content, setContent] = useState("")
  const comments = site?.comments;
  const commentsData = useMemo(() => comments, [comments]);
  const tagsData = useMemo(() => tags, [tags]);
  const onKeyUp = async (e: any) => {
    // if (e.code === 'NumpadEnter' || e.code === 'Enter') {
    //   const res = await handleCreateComment(String(site?.id) , { content });
    // }

  }
  return (
    <FormContainer className="form">
      <h5>Comentários</h5>
      <Input placeholder="Escrever um comentário..." onKeyUp={(k) => onKeyUp(k)} onChange={(e) => setContent(e.target.value)} />
      {
        (commentsData || [])?.length > 0 && (
          <>
            {
              commentsData?.map((comment) => (
                <div className="comment" key={comment?.id}>
                  <p>{comment?.content}</p>
                  <div className="footer">
                    <span>{String(dayjs(comment?.updated_at).format("DD/MM/YYYY [ ] mm:ss"))}</span>
                    <ul className="actions">
                      <li onClick={() => { }}>Editar</li>
                      <li onClick={() => { }}>Excluir</li>
                    </ul>
                  </div>
                </div>
              ))
            }
          </>
        )
      }

      <h5>Adicionar tag</h5>
      <div className="tags-content" >
        <ul className="ul-tags">
          {
            tagsData?.map((tag) => <LiContainer onClick={() => {
              // selectTag(tag)
            }} color={tag?.hex}></LiContainer>)
          }
        </ul>
      </div>
    </FormContainer>

  )
}
