import { ButtonContainer } from "./style";
import { ButtonFilterProps } from "./types";
import CalendarIcon  from '@/assets/icons/calendar.svg';
import FilterIcon  from '@/assets/icons/filter.svg';

export function ButtonFilter(props: ButtonFilterProps){
  const {title,icon, ...rest} = props;
  return (
    <ButtonContainer {...rest}>
      {icon === 'filter' && <img src={FilterIcon} alt="filter icon"/>}
      {icon === 'calendar' && <img src={CalendarIcon} alt="filter icon"/>}
      {(icon !== 'filter'&& icon !== 'calendar') && <img src={icon} alt="icon"/>}
      <p>{title}</p>
    </ButtonContainer>
  )
}
