import colors from "@/styles/colors";

export default function CheckIcon(props: React.SVGProps<SVGSVGElement>) {
  const { color = colors.white } = props;
  return (
    <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.4 1L3.4 5L1 2.6" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

