import colors from "@/styles/colors";
import { BadgeContainer } from "./style"
import { BadgeProps } from "./types"

export function Badge(props: BadgeProps) {
  const { value } = props;
  const backgroundColor = () => {
    if (value <= 30) {
      return colors.dangerDark;
    } else if (value > 30 && value < 80) {
      return colors.wargingDark;
    } else {
      return colors.successDark;
    }
  };
  const borderColor = () => {
    if (value <= 30) {
      return colors.dangerLight;
    } else if (value > 30 && value < 80) {
      return colors.wargingLight;
    } else {
      return colors.successLight;
    }
  };
  return (
    <BadgeContainer
     className="badgeCustom"
      backgroundColor={backgroundColor()}
      borderColor={borderColor()}>
      <p>{value}</p>
    </BadgeContainer>
  )
}
