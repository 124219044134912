import colors from '@/styles/colors';
import styled from 'styled-components';
import SearchIcon from '@/assets/icons/search.svg';

export const InputContainer = styled.input`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 11px 16px 11px 50px;
    max-width: 22em;
    width: 100%;
    border: 1px solid ${colors.grayExtraLight};
    border-radius: 7px;
    background: url(${SearchIcon});
    background-repeat: no-repeat;
    background-position: 12px;
`;
