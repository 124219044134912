import GlobalStyle from './styles/global';
import RoutesComponent from './routes';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

export const queryClient =  new QueryClient({
  defaultOptions:{
    queries:{
      retry: 3
    }
  }
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <GlobalStyle />
      <RoutesComponent />
    </QueryClientProvider>
  );
}

export default App;
