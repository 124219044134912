
import colors from "@/styles/colors";

export default function IndividualIcon(props: React.SVGProps<SVGSVGElement>) {
    const { color = colors.grayDark } = props;
    return (
        <svg width="24" height="24" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-inside-1_548_2473" fill="white">
                <rect width="9" height="9" rx="1" />
            </mask>
            <rect width="9" height="9" rx="1" stroke={color} strokeWidth="2.4" mask="url(#path-1-inside-1_548_2473)" />
            <mask id="path-2-inside-2_548_2473" fill="white">
                <rect y="11" width="9" height="9" rx="1" />
            </mask>
            <rect y="11" width="9" height="9" rx="1" stroke={color} strokeWidth="2.4" mask="url(#path-2-inside-2_548_2473)" />
            <mask id="path-3-inside-3_548_2473" fill="white">
                <rect x="12" width="9" height="9" rx="1" />
            </mask>
            <rect x="12" width="9" height="9" rx="1" stroke={color} strokeWidth="2.4" mask="url(#path-3-inside-3_548_2473)" />
            <circle cx="16.5" cy="15.5" r="3.9" stroke={color} strokeWidth="1.2" />
        </svg>

    )
}