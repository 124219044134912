import colors from '@/styles/colors';
import fonts from '@/styles/fonts';
import styled from 'styled-components';

export const FormContainer = styled.div`
   /* position: absolute; */
   width: 21.688em;
   height: auto;
   left:7em;
   top: 7em;
   background: #FFFFFF;
   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
   border-radius: 10px;
   padding:24px 16px;
         
   h5{
      font-size: 14px;
      font-family: ${fonts.Inter.Medium};
      color: ${colors.blueDark};
      margin-bottom: 24px;
   }
   .comment{
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction:column;
      margin-bottom: 32px;

      p{
         font-size: 14px;
         font-family: ${fonts.Inter.Regular};
         color: ${colors.blackDark};
         margin-bottom: 8px;
      }
      .footer{
         display: flex;
         justify-content: space-between;
         align-items: center;
         width: 100%;
         span{
            font-size: 14px;
            font-family: ${fonts.Inter.Regular};
            color: ${colors.grayDark};
         }
         .actions{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            gap:20px;
            list-style: none;
            font-size: 14px;
            font-family: ${fonts.Inter.Regular};
            color: ${colors.grayDarker};
            li {
               cursor: pointer;
               text-decoration: underline;
            }
         }
      }
   }
  .tags-content{
   display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      .ul-tags{
         display: flex;
         justify-content: center;
         align-items: center;
         flex-direction: row;
         gap:20px;
         list-style: none;
      }
   }
  
`;

export const LiContainer = styled.li<{color:string}>`
    cursor: pointer;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background-color: ${p=>p.color};
    /* :first-child{
      cursor: not-allowed;
    } */
`;