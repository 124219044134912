import colors from '@/styles/colors';
import styled from 'styled-components';

export const Container = styled.div`
width: 100%;

.footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    font-size:15px;
    color:${colors.blackLight};    
    .indicator{
        display: flex;
        justify-content: center;
        align-items: center;
        height:45px;
        background-color:${colors.grayExtraLight};
        border:none;
        margin-left:10px;
        border-radius:4px;
        min-width: 160px;
    }
  
    .control{
      display:flex;
      justify-content:center;
      align-items:center;
      .euruTw{
        margin-left: 10px;
      }
      .css-1rdxhju-control{
        height:45px;
        border-color:${colors.white};
      }

      button{
      height:45px;
      padding:15px 46px;
      background-color:${colors.grayExtraLight};
      border:none;
      margin-left:10px;
      border-radius:4px;
      cursor: pointer;
      font-size:15px;
      color:${colors.blackLight};
     }
    }

  }
`;