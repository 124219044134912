export default{
    transparent:'transparent',
    white:'#ffffff',
    black: '#000000',
    blackDark: '#27272D',
    blackDarker: '#2D2935',
    blackLight: '#35353D',
    blueDark: '#1D284C',
    primary: '#FF6F53',
    negative: '#F97474',
    disabled: '#E4E3EB',
    grayDark: '#8E8E9D',
    grayDarker: '#737380',
    grayNeutral: '#B2B2C2',
    neutral: '#9D9AAD',
    gray: '#C7C7D6',
    grayLight: '#E5E5E9',
    grayExtraLight: '#F0F0F0',
    graySoft:'#EAEAEA',
    gray100:'#F9F9F9',
    dangerDark:'#EB6E63',
    dangerLight:'#FF998F',
    wargingDark:'#F2C041',
    wargingLight:'#FCD675',
    successDark:'#8ACD8A',
    successLight:'#B3E9AD',
}