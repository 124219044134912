import colors from '@/styles/colors';
import styled from 'styled-components';
import Banner  from '@/assets/icons/background.svg';
import fonts from '@/styles/fonts';

export const LoginContainer = styled.main`
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%;
   background: url(${Banner});
   background-repeat: no-repeat;
   background-size: 100%;
   background-position: bottom;
`;

export const FormContainer = styled.form`
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   width: 30%;

   img{
      margin-top: 68px;
      margin-bottom: 76px;
   }
   h1{
      margin-bottom: 48px;
   }

   @media (max-width:768px) {
      width: 80%;
   }

`;
