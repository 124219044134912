import { QuotationCreate, Quotations } from '@/types/quotation.types';
import api from './api';

export async function handleCreateQuotation(params: QuotationCreate.Request) {
    return await api.post<QuotationCreate.Response>('Quotations/NewQuotation', { ...params });
}

export async function handleGetQuotations (query?:Quotations.Request) {
    const queryParams = query ? `?page=${query?.page}&perPage=${query?.perPage}`: '';
    return await api.get<Quotations.Response>(`Quotations/ListQuotations${queryParams}`, {});
}
