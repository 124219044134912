import { useEffect, useMemo, useState } from "react";
import { CardContainer, ButtonContainer, RowContent } from "./style";
import { CardProps } from "./types";
import OptionsIcon from "@/assets/icons/options";
import EditIcon from "@/assets/icons/edit";
import TrashIcon from "@/assets/icons/trash";
import AddIcon from "@/assets/icons/add";
import HistoryIcon from "@/assets/icons/history";
import dayjs from 'dayjs';
import 'dayjs/plugin/localeData';
import 'dayjs/locale/pt-br';
import { Capitalize } from "@/helpers";
import Currency from 'currency.js';

export function Card(props: CardProps) {
  const {
    title = 'Cotações dolar mensal',
    quotations,
    taxs,
    titleButtonLeft = 'Nova cotação',
    titleButtonRight = 'Histórico',
    iconButtonLeft = <AddIcon />,
    iconButtonRight = <HistoryIcon />,
    onClickButtonLeft,
    onClickButtonRight
  } = props;
  const quotationsList = useMemo(() => quotations, [quotations]);
  const taxsList = useMemo(() => taxs, [taxs]);
  const [rowSelected, setRowSelected] = useState('');
  const localeData = require('dayjs/plugin/localeData');
  dayjs.extend(localeData);
  dayjs.locale('pt-br');
  const months = dayjs().localeData().monthsShort();

  const onClick = (id: string) =>  {
    setRowSelected(id);
    if(id === rowSelected){
      setRowSelected('');
    }
  }

  useEffect(()=>{
    setTimeout(() => {if(rowSelected) setRowSelected('') }, 20000);
  },[rowSelected]);
  return (
    <CardContainer>
      <p>{title}</p>
      <div className="card-body">
        {
          (quotationsList?.length||0) > 0 && (quotationsList||[])?.map((item,i) => item && (
            <div className="row" key={i}>
              <span>{Capitalize(months[Number(item?.month) - 1])} {item?.year}</span>
              <div className="row-value">
                <span>{String(Currency(item?.price|| '').format({symbol: 'R$ ', decimal: ',', separator: '.'}))}</span>
                <RowContent show={rowSelected === String(item?.id)}>
                  <button
                    className="action-control"
                    onClick={() => onClick(String(item?.id))}>
                    <OptionsIcon />
                  </button>
                  <div className="row-actions">
                    <button><EditIcon /></button>
                    <button><TrashIcon /></button>
                  </div>
                </RowContent>
              </div>
            </div>
          ))
        }
        {
          (taxsList?.length||0) > 0 && (taxsList||[])?.map((item,i) => item && (
            <div className="row" key={i}>
              <span>{Capitalize(months[Number(dayjs(item?.created_at)?.month())])} {String(dayjs(item?.created_at).year()).slice(2,4)}</span>
              <div className="row-value">
                <span>{item?.percentage} %</span>
                <RowContent show={rowSelected === String(item?.id)}>
                  <button
                    className="action-control"
                    onClick={() => onClick(String(item?.id))}>
                    <OptionsIcon />
                  </button>
                  <div className="row-actions">
                    <button><EditIcon /></button>
                    <button><TrashIcon /></button>
                  </div>
                </RowContent>
              </div>
            </div>
          ))
        }

      </div>
      <div className="card-footer">
        <ButtonContainer onClick={onClickButtonLeft}>
          {iconButtonLeft}
          <span>{titleButtonLeft}</span>
        </ButtonContainer>
        <ButtonContainer onClick={onClickButtonRight}>
          {iconButtonRight}
          <span>{titleButtonRight}</span>
        </ButtonContainer>
      </div>
    </CardContainer>

  )
}
