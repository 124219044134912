import { FilterContainer, SelectedOption } from "./style"
import { FilterProps } from "./types"
import { Button } from "../../../components/Button";
import ArrowUpIcon from "@/assets/icons/arrow-up";
import { useState } from "react";
import { CheckBox } from "../../../components/CheckBox";
import { Chip } from "../../../components/Chip";

export function Filter(props: FilterProps) {
  const {
    optionsDomain,
    optionsRevenue,
    title = 'Domínio',
    onClick,
  } = props;
  const [openOption1, setOpenOption1] = useState(false);
  const [openOption2, setOpenOption2] = useState(false);
  const [optionList1, setOptionList1] = useState(['']);
  const [optionList2, setOptionList2] = useState(['']);

  const selectedOptions = (option: string, listOne = true) => (listOne ? optionList1 : optionList2)?.some(i => i === option);

  const onSelectOptions = (option: string, listOne = true) => {
    const uniqueOption = [...new Set(listOne ? optionList1 : optionList2)];
    const selected = selectedOptions(option, listOne);
    const filteredOption = selected ? '' : option;
    const index = uniqueOption.indexOf(option);

    if (selected) {
      uniqueOption.splice(index, 1);
      if (listOne) setOptionList1(uniqueOption);
      else setOptionList2(uniqueOption);
      return;
    }
    if (listOne) setOptionList1([...uniqueOption, filteredOption]);
    else setOptionList2([...uniqueOption, filteredOption]);
  };

  const removeOption = (option: string, listOne = true) => {
    const uniqueOption = [...new Set(listOne ? optionList1 : optionList2)];
    const index = uniqueOption.indexOf(option);
    uniqueOption.splice(index, 1);
    if (listOne) setOptionList1(uniqueOption);
    else setOptionList2(uniqueOption);
  };

  const showList1 = optionList1.length > 1;
  const showList2 = optionList2.length > 1;

  return (
    <FilterContainer>
      <span>Filtro</span>

      <div className="content">
        <label>{title}</label>
        <div className="select">
          <SelectedOption show={openOption1} onClick={() => setOpenOption1(!openOption1)}>
            <div className="selected-chips">
              {
                showList1 ?
                  optionList1.map((c) => c && <Chip key={c} title={c} onClick={() => removeOption(c)} />)
                  : (<span>Selecione</span>)
              }
            </div>
            <div className="arrow">
              <ArrowUpIcon />
            </div>
          </SelectedOption>
          {
            openOption1 && (
              <div className="options" id="options1">
                {
                  optionsDomain.map((item) => item&& (
                    <div className="option" key={item}>
                      <CheckBox isChecked={selectedOptions(item)} onClick={() => onSelectOptions(item)} />
                      <h3>{item}</h3>
                    </div>
                  ))
                }
              </div>
            )
          }
        </div>
        <label>Receita</label>
        <div className="select">
          <SelectedOption show={openOption2} onClick={() => setOpenOption2(!openOption2)}>
            <div className="selected-chips">
              {
                showList2 ?
                  optionList2.map((c) => c && <Chip key={c} title={c} onClick={() => removeOption(c, false)} />)
                  : (<span>Selecione</span>)
              }
            </div>
            <div className="arrow">
              <ArrowUpIcon />
            </div>
          </SelectedOption>
          {
            openOption2 && (
              <div className="options">
                {
                  optionsRevenue?.map(item => item &&(
                    <div className="option" key={item}>
                      <CheckBox isChecked={selectedOptions(item, false)} onClick={() => onSelectOptions(item, false)} />
                      <h3>{item}</h3>
                    </div>
                  ))
                }
              </div>
            )
          }
        </div>
      </div>


      <Button title="Filtrar" onClick={() => onClick(optionList1.filter(Boolean).concat(optionList2.filter(Boolean)))} />
    </FilterContainer>
  )
}
