import colors from '@/styles/colors';
import fonts from '@/styles/fonts';
import styled from 'styled-components';
import { NavHeaderProps } from './types';

export const NavHeaderContainer = styled.div<Pick<NavHeaderProps,'marginBottom'>>`
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   width: 100%;
   margin-bottom: ${p=>p.marginBottom || 0}px;
   
   nav{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;

      span{
         font-size: 2em;
         color:${colors.black};
         font-family: ${fonts.Inter.SemiBold};
         line-height: 39px;
         letter-spacing: 0px;
         margin-top: 50px;
      }
      @media screen and (max-width: 980px) {
         display: flex;
         flex-direction: column;
         gap: 20px;
      }
   }

   #control{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 48px;
      
      div{
         display: flex;
         justify-content: center;
         align-items: center;
         gap: 16px;
      }
      @media screen and (max-width: 980px) {
         display: flex;
         flex-direction: column;
         justify-content: center;
         gap: 20px;
      }
   }
`;
