import axios, { AxiosError } from 'axios';

const api = axios.create({ baseURL: process.env.REACT_APP_API_URL_STG });

api.interceptors.request.use(
    config => {
        const token  = localStorage.getItem("token");
        config.headers['Authorization'] = token? `Bearer ${token}`: '';

        config.validateStatus = (status) => {
            if (status === 401) {
                localStorage.clear();
                // redirect to login
            }

            return true;
        };
        return { ...config };
    },
    async (error: AxiosError) => {
        const { message } = error;

        if (message === 'Network Error') {
            console.log('Network Error');
        }
        return Promise.reject(error);
    },
);

api.interceptors.response.use(
    (res) => res,
    async (error: AxiosError) => {
        const { message } = error;

        if (message === 'Network Error') {
            console.log('Network Error');
        }
        return Promise.reject(error);
    },
);

export default api;