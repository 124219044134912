import colors from "@/styles/colors";

export default function FilterColIcon(props: React.SVGProps<SVGSVGElement>) {
  const { color = colors.gray } = props;
  return (
    <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.0316 5.00002C0.581598 5.00002 0.268932 4.79569 0.0935983 4.38702C-0.081735 3.97836 -0.0107357 3.61602 0.306598 3.30002L2.9066 0.700025C3.0066 0.600025 3.11493 0.525025 3.2316 0.475025C3.34826 0.425025 3.47326 0.400024 3.6066 0.400024C3.73993 0.400024 3.86493 0.425025 3.9816 0.475025C4.09826 0.525025 4.2066 0.600025 4.3066 0.700025L6.9066 3.30002C7.22326 3.61669 7.29393 3.97936 7.1186 4.38802C6.94326 4.79669 6.63093 5.00069 6.1816 5.00002H1.0316Z" fill={color}/>
      <path d="M2.90573 12.3L0.305725 9.7C-0.0109418 9.38333 -0.0816084 9.021 0.093725 8.613C0.269058 8.205 0.581392 8.00067 1.03073 8H6.18072C6.63072 8 6.94339 8.20433 7.11872 8.613C7.29406 9.02167 7.22306 9.384 6.90573 9.7L4.30572 12.3C4.20572 12.4 4.09739 12.475 3.98073 12.525C3.86406 12.575 3.73906 12.6 3.60573 12.6C3.47239 12.6 3.34739 12.575 3.23073 12.525C3.11406 12.475 3.00573 12.4 2.90573 12.3Z" fill={color}/>
    </svg>
  )
}

