import colors from '@/styles/colors';
import fonts from '@/styles/fonts';
import styled from 'styled-components';
import { BadgeProps } from './types';

export const BadgeContainer = styled.div<Pick<BadgeProps,'backgroundColor'|'borderColor'>>`
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   min-width: 36px;
   height: 24px;
   background: ${p=>p.backgroundColor || colors.transparent};
   mix-blend-mode: normal;
   border: 1px solid ${p=>p.borderColor || colors.transparent};
   box-shadow: 0px 11px 18px -5px rgba(1, 8, 58, 0.04);
   border-radius: 15px;
   padding: 1px 10px;
   p{
      color: ${colors.white};
      font-size: 14px;
      line-height: 22px;
      font-family: ${fonts.Inter.Medium};
      margin: 0px;
   }
`;
